import firebase from "firebase";
import _ from 'lodash';
import moment from "moment";
import React, {useEffect, useState} from "react";
import ReactTimeAgo from 'react-time-ago';
import JavascriptTimeAgo from 'javascript-time-ago';
import pl from 'javascript-time-ago/locale/pl';
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
    FaCalendar,
    FaClock,
    FaCoffee,
    FaDoorClosed,
    FaDoorOpen,
    FaEye,
    FaEyeSlash,
    FaHourglass,
    FaHourglassHalf,
    FaLock,
    FaMobileAlt,
    FaMoon,
    FaPeopleCarry,
    FaTeeth,
    FaTeethOpen,
    FaUser,
    FaUserSlash
} from 'react-icons/fa';
import Container from "react-bootstrap/Container";
import {Spinner, Table} from "react-bootstrap";

JavascriptTimeAgo.locale(pl);

export function Gate({uuid, name, ...authInfo}) {
    const [disarmedUntil, setDisarmedUntil] = useState(null),
        [gateStatus, setGateStatus] = useState({}),
        [isDisarmed, setIsDisarmed] = useState(null),
        [eventsVisible, toggleEvents] = useState(false);

    // Subscribe for updates of Gate document in Firestore
    useEffect(() => {
        const gateRef = getGate(uuid);
        return gateRef.onSnapshot((gate) => {
            const gateStatus = gate.data(),
                disarmedUntil = gateStatus?.disarmedUntil;
            setDisarmedUntil(disarmedUntil && moment(disarmedUntil));
            setGateStatus(gateStatus || {});
        });
    }, [uuid]);   // Empty dependencies list to avoid infinite loop

    // Hook for updating isDisarmed after disarmedUntil timestamp is reached
    useEffect(() => {
        (async function updateIsDisarmed() {
            console.log("updateIsDisarmed: ", isDisarmed, disarmedUntil);
            if (disarmedUntil) {
                setIsDisarmed(disarmedUntil && disarmedUntil >= moment());
                const miliseconds = disarmedUntil.diff(moment(), 'miliseconds');
                if (miliseconds > 0) {
                    console.log(`Awaiting ${miliseconds / 1000} secs to ${disarmedUntil}`);
                    await new Promise(r => setTimeout(r, miliseconds + 1));
                    // disarmedUntil should be expired now (unless it was prolonged):
                    const nowIsDisarmed = disarmedUntil && disarmedUntil >= moment();
                    console.log("Calling setIsDisarmed", nowIsDisarmed, disarmedUntil);
                    setIsDisarmed(nowIsDisarmed);
                } else {
                    console.log("Armed, not waiting.");
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disarmedUntil]);

    return (<Card>
        <Card.Header>
            <Button variant="link"
                    title="Click to show events"
                    onClick={() => {toggleEvents(!eventsVisible);}}
            >{name}</Button>
        </Card.Header>
        <Card.Body>
            <Container>
                {eventsVisible && <GateEvents uuid={uuid}/>}
                <Row className="pt-3 pb-3">
                    <Col><GateOpenClosed gateStatus={gateStatus}/></Col>
                    <Col><GateStatus disarmedUntil={disarmedUntil} gateStatus={gateStatus}/></Col>
                </Row>
                {isDisarmed && <Row lg={12} className="pt-3 pb-3"><Col><ArmButton uuid={uuid} {...authInfo} /></Col></Row>}
                <DisarmButtons uuid={uuid} {...authInfo} isDisarmed={isDisarmed}/>
            </Container>
        </Card.Body>
    </Card>);
}

function GateEvents({uuid}) {
    // Subscribe for updates of Gate document in Firestore

    const [events, setEvents] = useState(null);

    useEffect(() => {
        (async function retrieveEvents() {
            const gateRef = getGate(uuid);
            const eventSnapshots = await gateRef.collection('events')
                .orderBy('timestamp', 'desc')
                .limit(1000).get();
            const events = _.map(eventSnapshots.docs, (doc) => doc.data());
            setEvents(events);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);

    function getOpenPeriods(events) {
        class OpenPeriod {
            start = null;
            end = null;
            opened = 0;
            closed = 0;
            isDisarmed = null;

            constructor (end, isDisarmed) {
                this.end = this.start = moment(end);
                this.isDisarmed = isDisarmed
            }
        };

        return _.reduce(events, (openPeriods, evt) => {
            if (evt.event !== 'opened' && evt.event !== 'closed') {
                return openPeriods;
            }
            const lastPeriod = _.last(openPeriods),
                isNewPeriod = !lastPeriod || !lastPeriod.start || lastPeriod.start.diff(evt.timestamp, 'minutes') > 20,
                currPeriod = isNewPeriod ? new OpenPeriod(evt.timestamp, evt.isDisarmed) : lastPeriod;

            if (isNewPeriod) {
                openPeriods.push(currPeriod);
            }
            currPeriod[evt.event] += 1;
            currPeriod.start = moment(evt.timestamp);
            if (evt.isDisarmed !== currPeriod.isDisarmed) {
                currPeriod.isDisarmed = null
            }
            return openPeriods;
        }, [])
    }

    return events === null ?
        (<Spinner animation="border" role="status"></Spinner>) :
        (<Row className="pt-3 pb-3" lg={12}><Col>
        <Table striped bordered size="sm" className='history'>
            <thead>
                <tr><th>Otwarcie</th><th>Szczegóły</th></tr>
            </thead>
            <tbody>
            {_.map(getOpenPeriods(events), (period) => <GateOpen period={period} />)}
            </tbody>
        </Table>
    </Col></Row>);
}

function GateOpen({period}) {
    return (<tr>
        <td>{period.start.format('YYYY-MM-DD HH:mm')} - {period.end.format('HH:mm')}</td>
        <td>{period.isDisarmed === false && "🚨"} Otwarto {period.opened} razy, zamknięto {period.closed} razy</td>
    </tr>);
}


function getGate(uuid) {
    return firebase.firestore().collection('gates').doc(uuid);
}

async function disarmGate({uuid, disarmedMinutes, disarmedUntil, user}) {
    const gateRef = getGate(uuid);
    let timestamp = moment();

    disarmedMinutes = disarmedMinutes || null;
    disarmedUntil = disarmedUntil || timestamp.clone().add({minutes: parseInt(disarmedMinutes || 0)});
    const event = disarmedUntil <= timestamp ? 'armed' : 'disarmed';

    console.log('disarmGate event: ', event, user.displayName || user.email);

    disarmedUntil = disarmedUntil.toISOString();
    timestamp = timestamp.toISOString();

    user = _.pick(user, 'email', 'displayName', 'uid');
    await gateRef.update({disarmedUntil, user, [event]: timestamp});

    await gateRef.collection('events').add({user, event, disarmedUntil, disarmedMinutes, timestamp});
}

function DisarmButton({uuid, children, disarmedMinutes, disarmedUntil, user, variant}) {
    return <Button variant={variant || "success"} size="lg" block
                   onClick={() => disarmGate({uuid, disarmedMinutes, disarmedUntil, user})}
                   className="mt-3 mb-3">
        {children}
    </Button>;

}

export function DisarmButtons({uuid, user, isDisarmed}) {
    const verb = isDisarmed ? "Przedłuż" : "Rozbrój",
        params = {user, uuid};

    return (
        <>
            <Row className="pt-3 pb-3"><Col xs={12} sm={6}>
                <DisarmButton disarmedMinutes={15} {...params}>
                    <FaHourglassHalf/> {verb} na kwadrans
                </DisarmButton>
            </Col><Col xs={12} sm={6}>
                <DisarmButton disarmedMinutes={60} {...params}>
                    <FaHourglass/> {verb} na godzinę
                </DisarmButton>
            </Col><Col>
                <DisarmButton disarmedMinutes={180} {...params}>
                    <FaClock/> {verb} na 3 godziny
                </DisarmButton>
            </Col><Col>
                <DisarmButton disarmedUntil={moment().set({hour: 22, minute: 0})} {...params}>
                    <FaMoon/> {verb} do 22:00
                </DisarmButton>
            </Col><Col>
                <DisarmButton disarmedUntil={moment().add(1, 'days').set({hour: 6, minute: 0})} {...params}>
                    <FaCoffee/> {verb} do rana
                </DisarmButton>
            </Col><Col>
                <DisarmButton disarmedUntil={moment().add(365, 'days')} {...params}>
                    <FaCalendar/> {verb} na rok
                </DisarmButton>
            </Col>
            </Row>
        </>
    );
}

export function ArmButton(params) {
    return <DisarmButton disarmedMinutes={0} variant="warning" {...params}><FaLock/>Uzbrój</DisarmButton>;
}

function TimeAgo({date, ...params}) {
    return date ? <ReactTimeAgo date={moment(date).toDate()} locale="pl" {...params}/> : <> </>;
}

export function GateStatus({disarmedUntil, gateStatus}) {
    const isDisarmed = disarmedUntil >= moment(),
        disarmed = gateStatus.disarmed && moment(gateStatus.disarmed),
        safeDeviceNearby = gateStatus.safeDeviceNearby && moment(gateStatus.safeDeviceNearby),
        isDisarmedByDevice = disarmed && safeDeviceNearby && (disarmed <= safeDeviceNearby);

    const cardImg = isDisarmed ?
        <div><FaEyeSlash size="6em"/></div> :
        <div><FaEye size="6em"/></div>;

    const cardTitle = isDisarmed ? (
            isDisarmedByDevice ? (<Card.Title> <FaMobileAlt/> Czujnik rozbrojony<br/>
                    <small>uzbroi się najwcześniej <TimeAgo date={disarmedUntil}/></small></Card.Title>) :
                <Card.Title> <FaUser/> Czujnik rozbrojony<br/><small>uzbroi się <TimeAgo date={disarmedUntil}/></small></Card.Title>) :
        <Card.Title>Czujnik uzbrojony</Card.Title>;

    const cardText = isDisarmed ? (
            isDisarmedByDevice ? (
                    <Card.Text>Został rozbrojony <TimeAgo date={gateStatus.disarmed}/> przez <strong>{gateStatus.safeDeviceName || gateStatus.safeDevice}</strong> co najmniej
                        do {disarmedUntil.format("HH:mm")}.</Card.Text>) :
                <Card.Text>Został rozbrojony <TimeAgo
                    date={gateStatus.disarmed}/> przez <strong>{gateStatus.user?.name || gateStatus.user?.email || "użytkownika"}</strong> do {disarmedUntil.format("HH:mm")}.</Card.Text>) :
        <Card.Text>Został uzbrojony <TimeAgo date={_.max([gateStatus.armed, gateStatus.disarmedUntil])}/>.</Card.Text>;

    return <Card>
        {cardImg}
        <Card.Body>
            {cardTitle}
            {cardText}
        </Card.Body>
    </Card>;
}

const DETECTORS = {
    gate: {
        titles: {closed: "Brama zamknięta", opened: "Brama otwarta"},
        icons: {closed: FaTeeth, opened: FaTeethOpen},
        eventNames: {closed: "Czujnik wykrył zamknięcie", opened: "Czujnik wykrył otwarcie"}
    },
    door: {
        titles: {closed: "Drzwi zamknięte", opened: "Drzwi otwarte"},
        icons: {closed: FaDoorClosed, opened: FaDoorOpen},
        eventNames: {closed: "Czujnik wykrył zamknięcie", opened: "Czujnik wykrył otwarcie"}
    },
    motionsensor: {
        titles: {nomotion: "Brak ruchu", motion: "Wykryto ruch"},
        icons: {nomotion: FaUserSlash, motion: FaPeopleCarry},
        eventNames: {nomotion: "Czujnik nie wykrywa ruchu", motion: "Czujnik wykrywa ruch"}
    }
}

export function GateOpenClosed({gateStatus}) {
    const kind = gateStatus.kind || 'door',
        detector = DETECTORS[kind] || DETECTORS['door'],
        states = _.keys(detector.titles),
        state = _.includes(states, gateStatus.gateState) ? gateStatus.gateState : _.first(states),
        currentTitle = detector.titles[state],
        CurrentIcon = detector.icons[state],
        eventName = detector.eventNames[state];

    const cardTitle = <Card.Title>{currentTitle}</Card.Title>;
    const cardImg = <div><CurrentIcon size="6em"/></div>;
    const cardText = <Card.Text>{eventName} <TimeAgo date={gateStatus[state]}/>.</Card.Text>;

    return <Card>
        {cardImg}
        <Card.Body>
            {cardTitle}
            {cardText}
        </Card.Body>
    </Card>;
}