import React from 'react';
import './App.css';
import _ from 'lodash';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import {FirebaseAuthProvider, IfFirebaseAuthed, IfFirebaseUnAuthed} from "@react-firebase/auth";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import {FaSignInAlt, FaSignOutAlt} from 'react-icons/fa';
import {Async} from "react-async";
import {Gate} from "./Gate";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const firebaseConfig = {
    apiKey: "AIzaSyDn-qIAIMIp1EHk-TernE2VcQm9pSZRm4A",
    authDomain: "door.watch",
    databaseURL: "https://gateguard-275408.firebaseio.com",
    projectId: "gateguard-275408",
    storageBucket: "gateguard-275408.appspot.com",
    messagingSenderId: "387197911855",
    appId: "1:387197911855:web:eafa9b44852e537a4b17da"
};

firebase.initializeApp(firebaseConfig);

async function iterGates() {
    const gates = await firebase.firestore().collection('gates').get();
    return _.sortBy(_.filter(gates.docs, (gate) => !gate.get('disabled')), (gate) => gate.get('sort'));
}


function Gates(authInfo) {
    return <Async promiseFn={iterGates}>
        <Async.Fulfilled>
            {(gates) => _.map(gates, (gate) => <Gate {...authInfo} key={gate.id} uuid={gate.id} name={gate.get('name')}/>)}
        </Async.Fulfilled>
        <Async.Pending>Loading gates...</Async.Pending>
        <Async.Rejected>{error => <>{error}</>}</Async.Rejected>
    </Async>;
}

function App({errorReporter}) {
    return (
        <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
            <div className="App">
                <IfFirebaseUnAuthed>
                    {() => {
                        return <Container className="pt-5 pb-5">
                            <Button size="lg" variant="outline-success" onClick={async () => {
                                try {
                                    const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
                                    const result = await firebase.auth().signInWithPopup(googleAuthProvider);
                                    console.log("Logged in user: ", result?.user);
                                    result?.user && errorReporter.setUser(result.user.email);
                                } catch (err) {
                                    // Workaround missing stacktrace, required by error reporter:
                                    // eslint-disable-next-line no-ex-assign
                                    let errorWithStack;
                                    try {
                                        throw Error(err);
                                    } catch (e) {
                                        errorWithStack = e;
                                    }
                                    throw errorWithStack;
                                }
                            }}>
                                <FaSignInAlt/>Zaloguj przez Google
                            </Button>
                        </Container>;
                    }}
                </IfFirebaseUnAuthed>

                <IfFirebaseAuthed>
                    {(authInfo) => {
                        return <>
                            <Gates {...authInfo}/>
                            <Row className="pt-5 pb-5"><Col>
                                <Button size="lg" variant="outline-secondary" onClick={() => firebase.auth().signOut()}>
                                    <FaSignOutAlt/> Wyloguj
                                </Button>
                            </Col></Row>
                        </>;
                    }}
                </IfFirebaseAuthed>
            </div>
        </FirebaseAuthProvider>
    );
}

export default App;
