import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import StackdriverErrorReporter from 'stackdriver-errors-js';


function setupErrorReporter() {
    if (process.env.NODE_ENV !== 'production') {
        return {
            report() {
                console.error('stub errorReporter.report', ...arguments);
            },
            setUser(user) {
                console.log('stub errorReporter.setUser', ...arguments);
            }
        };
    }
    console.log("Production mode");
    const errorReporter = new StackdriverErrorReporter();
    errorReporter.start({
        key: 'AIzaSyA02OHWpUHm8GDcVea9cUN6EJNuLDyI2-I',
        projectId: 'gateguard-275408',
    });
    return errorReporter;
}

const errorReporter = setupErrorReporter();

ReactDOM.render(
    <React.StrictMode>
        <App errorReporter={errorReporter}/>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
